<template>
  <div>
    <sport-card
      v-if="theCurrentPage === 1"
      :header-data="data.headerData"
      :teams="teams"
      :standings="standings"
    />

    <Scores
      style="padding-bottom: 10px;"
      :games="games"
    />

    <!-- <div>
      <b-pagination-nav
        v-model="data.currentPage"
        style="border-radius: 0;"
        size="lg"
        :number-of-pages="data.pages"
        :link-gen="linkGen"
        use-router
        @pager-update:page="theCurrentPage = $event"
      />
      {{ theCurrentPage }} Current page: {{ data.currentPage }}, Number of Pages: {{ data.pages }}
    </div> -->

    <!-- <div
      v-if="data.search !== '' && data.posts.length > 0"
      class="alert success"
    >
      <strong>{{ data.posts.length }} POSTS WERE FOUND FOR YOUR
        "<span class="text-success">{{ data.search }}</span>" SEARCH.</strong>
    </div>
    <div
      v-else-if="data.search !== '' && data.posts.length === 0"
      class="alert failure"
    >
      <strong>NOTHING FOUND FOR
        "<span class="text-failure">{{ data.search }}</span>"
      </strong>

    </div> -->

    <!-- <b-overlay
      :show="isLoading"
      opacity="0.7"
      size="lg"
      no-wrap
      fixed
    />

    <view-three-wide
      :posts="thePosts.posts.slice(0, 50)"
    />
     -->

    <b-overlay
      :show="isLoading"
      class="match-height col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12"
      opacity="0.7"
    >
      <post-composite
        :posts="thePosts.posts"
        :title="sport"
        title-extra="News"
        :title-extra-small="computedTitle"
      />
    </b-overlay>

    <Pager
      :page="data.currentPage"
      :pages="data.pages"
      :floating="false"
      @pager-page="updateCurrentPage"
    />

    <!-- <post-composite
      :posts="thePosts.posts"
    /> -->

    <b-img
      :src="require('@/config/images/ads/banner.png')"
      class="ad"
    />

    <!-- <view-composite
      :posts="thePosts.posts.slice(7, 30)"
      :sport="sport"
      title="More"
      title-extra="Posts"
      style="margin-bottom: 20px;"
    /> -->

    <!-- <b-navbar
      toggleable
      type="dark"
      variant="secondary"
      style="margin-bottom: 10px;"
    >
      <b-navbar-brand :href="`${sport}`">
        {{ sport }}
      </b-navbar-brand>
    </b-navbar> -->

    <!-- <b-card :title="sport">
      <b-card-text>End of sports: {{ sport }}</b-card-text>
    </b-card> -->

    <ScrollToTop />
  </div>
</template>

<script>
import {
  BImg,
  BOverlay,
  // BNavbar,
  // BNavbarBrand,
  // BPaginationNav,
} from 'bootstrap-vue'

import {
  watch, reactive, computed, toRefs,
} from '@vue/composition-api'

// import { subject as an } from '@casl/ability'
import store from '@/store'
import Pager from '@core/components/pager/Pager.vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
// import ViewComposite from './ViewComposite.vue'
import PostComposite from '../posts/PostComposite.vue'
// import ViewThreeWide from './ViewThreeWide.vue'
import Scores from './Scores.vue'
import SportCard from './SportCard.vue'
// import * as PostService from '../../services/PostService'

export default {
  components: {
    BImg,
    BOverlay,
    // BNavbar,
    // BNavbarBrand,
    // BPaginationNav,

    Pager,
    ScrollToTop,
    // ViewComposite,
    // ViewThreeWide,
    PostComposite,
    Scores,
    SportCard,
  },

  setup(props, context) {
    // let searchLoaded = true
    const data = reactive({
      search: '',
      posts: [],
      time: 0,
      pages: 1,
      total: 0,
      currentPage: null,
      headerData: { sport: '', image: '' },
      sportData: {},
    })
    const settings = reactive({
      theCurrentPage: null,
    })
    const sport = computed(() => context.root.$route.meta.pageTitle.toLowerCase())
    const teams = computed(() => {
      if (sport.toLowerCase === 'sports') {
        // console.log('Check instead if SPORT is a real sport first')
        return []
      }
      return store.getters['appData/getTeamsForSport'](sport)
    })
    data.headerData.sport = sport
    data.headerData.image = computed(() => store.getters['appData/getImageForSport'](sport))
    data.sportData = computed(() => store.getters['appData/getSportData'](sport))

    function linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    }

    // function get(cat) {
    //   store.dispatch('loader/pending')
    //   if (store.state.app.searchQueryText !== '') {
    //     // console.log(`called PostService.getAllPostsFromCategory(${cat}) + Search: ${store.state.app.searchQueryText}....results`)
    //     // return PostService.getAllPostsWithSearch(store.state.app.searchQueryText)
    //     return PostService.getAllPostsFromCategoryWithSearch(sport.value, store.state.app.searchQueryText)
    //       .then(response => {
    //         // Have to Map the data using the CASL ability 'an' function to 'Post' items.
    //         data.posts = response.data.data.data.map(item => an('Post', item))
    //         // console.log(`\n---------------POSTS for Search ${store.state.app.searchQueryText}-----------------------------------------------`)
    //         // console.log(data.posts.length)
    //         data.search = store.state.app.searchQueryText
    //         store.state.app.searchQueryText = ''
    //         // searchLoaded = true

    //         data.time = response.data.time
    //         data.count = response.data.count

    //         store.dispatch('loader/done')
    //       })
    //     // .catch(error => {
    //     // console.log('\n---------------ERROR-----------------------------------------------')
    //     // console.log(error)
    //     // })
    //   }
    //   return PostService.getAllPostsFromCategory(cat)
    //     .then(response => {
    //       // console.log(`called PostService.getAllPostsFromCategory(${cat})....results`)
    //       // Have to Map the data using the CASL ability 'an' function to 'Post' items.
    //       data.posts = response.data.data.data.map(item => an('Post', item))
    //       // console.log(`\n---------------POSTS for sport ${cat}-----------------------------------------------`)
    //       // console.log(data.posts.length)
    //       store.state.app.searchQueryText = ''
    //       data.search = ''
    //       // searchLoaded = false

    //       data.time = response.data.time
    //       data.count = response.data.count
    //       store.dispatch('loader/done')
    //     })
    //     // .catch(error => {
    //     //   console.log('\n---------------ERROR-----------------------------------------------')
    //     //   console.log(error)
    //     // })
    // }

    // const getPosts = async (origin, cat, search) => {
    //   // console.log(`\n -- async getPosts - [${origin}, ${cat}, ${search}] ------------------------------`)
    //   // console.log(`KeyWords: ${context.root.$route.meta.keywords}`)

    //   // store.getters.isLoading = true
    //   // console.log(cat !== '')
    //   // eslint-disable-next-line no-console

    //   // console.log(`Query Page: ${context.root.$route.query.page}`)

    //   if (search !== '') {
    //     store.dispatch('loader/pending')
    //     await get(cat).then(() => {
    //       store.dispatch('loader/done')
    //     })
    //   } else {
    //     const result = store.getters['appData/getPostsBySport'](cat, data.pageNum)
    //     console.log('\n\n ============= SPORT.VUE ===================')
    //     console.log()
    //     data.posts = result.posts
    //   }

    //   // store.getters.isLoading = false
    // }

    // const getAllPosts = async () => {
    //   console.log('get all posts called...does nothing for now')
    //   // getPosts('getAllPosts-onMounted', context.root.$route.meta.pageTitle.toLowerCase())
    // }

    // const isUpdated = computed(() => {
    //   // console.log(`\n -- COMPUTED - [${store.state.app.searchQueryText}] ------------------------------`)
    //   if (store.state.app.searchQueryText !== '') {
    //     getPosts('computed', '', store.state.app.searchQueryText)

    //     return true
    //   }
    //   return false
    // })

    // onMounted(getPosts)
    // watch(data, (newValue, oldValue) => {
    //   console.log(newValue)
    //   console.log(oldValue)
    // })

    // watch(() => {
    //   console.log('\n\n--------Sport.vue - PAGE TITLE CHANGED -----------------------')
    //   console.log(`  Page title: ${context.root.$route.meta.pageTitle}`)
    //   // console.log(`      Search: ${data.search}`)
    //   // console.log(`       Store: ${store.state.app.searchQueryText}`)
    //   getPosts('watch title changed', context.root.$route.meta.pageTitle.toLowerCase())
    // })
    // watch(isUpdated, val => {
    //   console.log('\n ---------------store.state.app.searchQueryText-------------------------------')
    //   console.log(val)
    // })
    // watch(data, (newValue, oldValue) => {
    //   console.log('\n ---------------data.search-------------------------------')
    //   console.log(oldValue)
    //   console.log(newValue)
    // })

    // watch(sport, (newValue, oldValue) => {
    //   console.log(`\n -- watch(sport = ${sport}, (newValue = ${newValue}, oldValue = ${oldValue}))------------------------------`)
    //   console.log(sport)
    //   // eslint-disable-next-line no-console
    //   // getPosts('getAllPosts-onMounted', newValue, store.state.app.searchQueryText)

    //   const result = store.getters['appData/getPostsBySport'](newValue)
    //   data.posts = result.posts
    // })

    // // This causes issues:
    // //   `watch(fn, options?)` signature has been moved to a separate API

    // watch(() => {
    //   console.log('\n -- WATCH: () -------------------------------')
    //   // console.log(context.root)
    //   // console.log(context.root.$route)
    //   // console.log(context.root.$route.meta.pageTitle.toLowerCase())
    //   // console.log(`----------SEARCH1: ${store.state.app.searchQueryText}`)
    //   // console.log(store.state.app.searchQueryText)
    //   // console.log(searchLoaded)
    //   // console.log(context.root.$route.fullPath)

    //   // if (!loaded) {
    //   //   console.log('---------------------------> GET POSTS for this route')
    //   // getPosts('From the () watch', context.root.$route.meta.pageTitle.toLowerCase(), store.state.app.searchQueryText)
    //   // }
    //   // console.log('searchLoaded')
    //   // console.log(searchLoaded)
    //   // console.log('context.root.$route.fullPath')
    //   // console.log(context.root.$route.fullPath)
    //   if (searchLoaded && !context.root.$route.fullPath.includes('search')) {
    //     getPosts('From the () watch', context.root.$route.meta.pageTitle.toLowerCase(), '')
    //   }
    //   // console.log(`----------SEARCH2: ${store.state.app.searchQueryText}`)
    //   if (store.state.app.searchQueryText !== '') {
    //     // console.log('----search with text -------------')
    //     console.log('\n KEYWORDS:')
    //     console.log(context.root.$route.meta.keywords)
    //     getPosts(`search text: ${store.state.app.searchQueryText}`, context.root.$route.meta.pageTitle.toLowerCase(), '')
    //   }
    // })

    const id = computed(() => context.root.$route.meta.pageTitle)

    watch(id, newVal => {
      // store.dispatch('appData/fetchPostsFromDatabase')
      const page = parseInt(context.root.$route.query.page, 10) || 1
      // const page = 1
      data.currentPage = page
      // store.dispatch('loader/show')
      store.dispatch('appData/fetchPostsFromDatabaseForCategory', { category: newVal.toLowerCase(), page })
      // store.dispatch('loader/hide')
    }, {
      immediate: true,
    })

    const page = computed(() => parseInt(context.root.$route.query.page, 10) || 1)
    const isLoading = computed(() => store.state.loader.isLoading)
    watch(page, newVal => {
      // store.dispatch('loader/show')
      store.dispatch('appData/fetchPostsFromDatabaseForCategory', { category: context.root.$route.meta.pageTitle.toLowerCase(), page: newVal })
      // store.dispatch('loader/hide')
    }, {
      immediate: true,
    })

    const thePosts = computed(() => {
      const results = store.getters['appData/getPostsForCurrentPage']
      data.total = results.total
      if (results.total > 0) {
        data.pages = Math.ceil(results.total / results.limit)
      } else {
        data.pages = 1
      }
      return results
    })

    // onMounted(getAllPosts)

    function updateCurrentPage(p) {
      settings.theCurrentPage = p
    }

    const computedTitle = computed(() => {
      if (settings.theCurrentPage === null || settings.theCurrentPage === 0) {
        return ''
      }
      return `Page ${settings.theCurrentPage} of ${data.pages} (${data.total} Posts)`
    })

    async function fetchGames(sportName) {
      store.dispatch('appData/fetchGames', { sport: sportName })
    }
    // const theSport = 'nhl'
    fetchGames(sport.value)
    const games = computed(() => {
      const x = store.getters['appData/getGames'](sport.value)
      return x
    })
    const standings = computed(() => store.getters['appData/getStandings'](sport.value))

    watch(sport, newVal => {
      fetchGames(newVal)
    }, {
      immediate: true,
    })

    return {
      ...toRefs(settings),
      data,
      isLoading,
      teams,
      linkGen,
      sport,
      // isUpdated,
      thePosts,
      // getAllPosts,
      updateCurrentPage,
      computedTitle,
      games,
      standings,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  padding-left: 85px;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.alert::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px 0 0 4px;
  width: 60px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.success::before {
  background-color: $secondary;
  background-size: 18px 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='%23fff' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E");
}

.failure::before {
  background-color: $danger;
  background-size: 18px 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='%23fff' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E");
}

.text-success {
  color: $secondary !important;
}

.text-failure {
  color: $danger !important;
}

.ad {
  width: 100%;
  height: auto;
}
</style>
