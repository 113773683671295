<template>
  <div>

    <b-card
      class="profile-header mb-2"
      body-class="p-0"
    >
      <div class="main-hero">
        <div class="image-wrapper">
          <img
            v-image-fall-back
            :src="headerData.image"
            @error="imageLoadError"
          >
        </div>

        <div class="content">
          <Standings
            :standings="standings"
          />
        </div>
      </div>

      <!-- profile picture -->
      <div class="position-relative">
        <div
          class="profile-img-container d-flex align-items-center"
        >
          <!-- profile title -->
          <div class="profile-title">
            {{ headerData.sport.toUpperCase() }}
          </div>
          <!--/ profile title -->

          <div
            class="profile-img"
            :style="`background-color: white; border: 0.357rem solid white;`"
          >
            <b-link>
              <router-link
                :to="`/${headerData.sport}`"
                style="color: white;"
              >
                <b-img
                  fluid
                  rounded
                  :src="require('@/assets/images/icons/sports/hockey.svg')"
                  style="width: 40px;"
                />
              </router-link>
            </b-link>
            <!-- <b-img
              :src="headerData.logo"
              rounded
              alt="profile photo"
              style="padding: 7px; height: 7.1rem; overflow: hidden;"
            /> -->
            <!-- <b-row id="profile-info">
            <b-col> -->
            <!-- <swiper
              class="swiper-multiple"
              :options="swiperOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              style="width: 14.0rem; justify-content: center;"
            >
              <swiper-slide
                v-for="(team, index) in filteredTeams"
                :key="index"
              >
                <router-link
                  :to="`/${headerData.sport}/${team.name.toLowerCase()}`"
                  style="color: white;"
                >
                  <b-img
                    :src="team.logo"
                    fluid
                    rounded
                  />
                </router-link>
              </swiper-slide>

              <swiper-slide>
                <a
                  v-b-modal.modal-scrollable
                >
                  <feather-icon
                    icon="HeartIcon"
                    size="20"
                    style="color: primary; "
                    fill="white"
                  />
                </a>
              </swiper-slide>

              <div
                slot="pagination"
                class="swiper-pagination"
                style="position: unset; margin-bottom: -5px;"
              />
            </swiper> -->
            <!-- </b-col> -->
            <!-- latest photo loop -->
            <!-- <b-col
              v-for="team in filteredTeams"
              :key="team.logo"
              md="3"
              cols="3"
              class="profile-latest-img"
            >
              <b-link>
                <router-link
                  :to="`/${headerData.sport}/${team.name.toLowerCase()}`"
                  style="color: white;"
                >
                  <b-img
                    fluid
                    rounded
                    :src="team.logo"
                    :alt="team.logo.slice(5)"
                  />
                </router-link>
              </b-link>
            </b-col> -->
            <!-- latest photo loop -->
            <!-- </b-row> -->
          </div>
        </div>
      </div>
      <!--/ profile picture -->
    </b-card>

  </div>
</template>

<script>
import {
  VBModal,
  BCard,
  BLink,
  BImg,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import {
  computed, reactive, toRefs,
} from '@vue/composition-api'

// import style
// import 'swiper/swiper-bundle.min.css'
// import store from '@/store'

// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper-bundle.css'
// import Cookies from 'js-cookie'

import Standings from './Standings.vue'

export default {
  components: {
    // Card components
    BCard,
    BLink,
    BImg,

    // Swiper,
    // SwiperSlide,
    Standings,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
    // swiper: directive,
  },

  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    teams: {
      type: Array,
      default: () => [],
    },
    standings: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, context) {
    const settings = reactive({
      selectedTab: 'pics',
    })

    // const swiperOptions = {
    //   slidesPerView: 4,
    //   spaceBetween: 5,
    //   // Responsive breakpoints
    //   breakpoints: {
    //     // // when window width is >= 320px
    //     // 320: {
    //     //   slidesPerView: 2,
    //     //   spaceBetween: 20,
    //     // },
    //     // // when window width is >= 480px
    //     // 480: {
    //     //   slidesPerView: 3,
    //     //   spaceBetween: 30,
    //     // },
    //     // // when window width is >= 640px
    //     // 640: {
    //     //   slidesPerView: 4,
    //     //   spaceBetween: 40,
    //     // },
    //   },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //   },
    // }

    // const favoriteTeams = computed(() => {
    //   const favoritesStr = Cookies.get('favoriteTeams') || ''
    //   if (favoritesStr === '') {
    //     return {}
    //   }

    //   const dict = {}
    //   const favorites = favoritesStr.split(';')
    //   favorites.forEach(favorite => {
    //     const splits = favorite.split(':', 2)
    //     if (splits.length === 2) {
    //       const key = splits[0]
    //       const value = splits[1]
    //       if (key in dict) {
    //         dict[key].push(value)
    //       } else {
    //         dict[key] = [value]
    //       }
    //     }
    //   })
    //   return dict
    // })

    // const filteredTeams = computed(() => {
    //   const s = props.headerData.sport
    //   return props.teams.filter(team => (s in favoriteTeams.value && favoriteTeams.value[s].includes(team.name.toLowerCase())))
    // })

    //   if (props && 'headerData' in props && props.headerData !== undefined) {
    //     const randomId = Math.floor(Math.random() * props.headerData.images.length)
    //     return props.headerData.images[randomId]
    //   }
    const randomHeaderImage = computed(() => '')

    function selectTab(which) {
      settings.selectedTab = which
      context.emit('selectedTab', which)
    }

    function imageLoadError() {
      /* eslint-disable no-console */
      console.log('image load error----------------------------------------------------------------------')
      /* eslint-enable no-console */
    }

    return {
      ...toRefs(settings),
      // swiperOptions,
      // favoriteTeams,
      // filteredTeams,
      randomHeaderImage,
      imageLoadError,
      selectTab,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
// Description: Page content different types of users page layouts SCSS.

@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '@core/scss/base/components/include'; // Components includes

// User profile Scss
/*-------------profile header section---------*/
.profile-header {
  overflow: hidden;

  // profile picture container
  .profile-img-container {
    position: absolute;
    bottom: 2.0rem;
    left: 2.0rem;
    z-index: 2;
    flex-direction: column;

    // profile image
    .profile-img {
      border: 0.357rem solid $secondary;
      background-color: $secondary;
      border-radius: $card-border-radius;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 600px) {
      bottom: 1.0rem;
      flex-direction: row;
    }
  }

  // profile navbar padding
  .profile-header-nav {
    .navbar {
      padding: 0.8rem 1rem;

      // navbar toggle button
      .navbar-toggler {
        line-height: 0;
      }
    }
  }
}

/*-------- profile info section --------*/
#profile-info {
  // profile star icons
  .profile-star {
    color: $gray-100;

    i,
    svg {
      // filled star icons
      &.profile-favorite {
        fill: $warning;
        stroke: $warning;
      }
    }
  }

  // filled heart icons
  .profile-likes {
    fill: $danger;
    stroke: $danger;
  }

  // progress-bar height
  .profile-polls-info {
    .progress {
      height: 0.42rem;
    }
  }
}

//profile-latest-img - hover effect
.profile-latest-img {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-4px) scale(1.2);
    z-index: 10;
  }
  img {
    margin-top: 0.28rem;
    height: 2rem;
    width: 2rem;
  }
}

// Load More Button - Block UI
.block-element {
  .spinner-border {
    border-width: 0.14rem;
  }
}
.profile-title {
  font-size: 4.0rem;
  font-weight: 600;
  text-shadow: white 1px 0 10px; -webkit-text-stroke-width: 0.2px; -webkit-text-stroke-color: $primary;
}

// Latest Photo Section - Image size
// @include media-breakpoint-down(md) {
//   #user-profile {
//     .profile-latest-img {
//       img {
//         width: 100%;
//       }
//     }
//   }
// }

@include media-breakpoint-up(md) {
  // Nothing
}

// profile img and title
@include media-breakpoint-down(xs) {
  .profile-header {
    .profile-img-container {
      .profile-title {
        font-size: 2.0rem;
        font-weight: 600;
        margin-right: 1.0rem;
        text-shadow: $primary 1px 0 10px; -webkit-text-stroke-width: 0.2px; -webkit-text-stroke-color: black;
      }
    }
  }
}

.main-hero {
  height: 100%;
  display: table;
  width: 100%;
  // padding-bottom: 100px;
}

.main-hero .image-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 250px;
}

.main-hero .content {
  max-width: 350px;
  margin: -225px 20px 75px auto;
  display: table;
  // width: 100%;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 5px 10px 0px #0000008a;
  padding: 0px;
  position: relative;
}

.main-hero .content p {
  font-size: 20px;
}

/*//for tablet*/
@media only screen and (max-width: 991px) {
  .main-hero .content {
    max-width: 350px;
  }
}

/*//for mobile*/
@media only screen and (max-width: 600px) {
  .main-hero .image-wrapper img {
    max-height: 150px;
  }

  .main-hero .content {
    max-width: 350px;
    padding: 0px;
    margin: -50px auto 75px auto;
  }
  .main-hero .content p {
      font-size: 12px;
  }
}
</style>
