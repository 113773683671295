<template>
  <div
    v-if="standings"
    style="display: flex;"
  >
    <table
      v-for="(key) in Object.keys(standings)"
      :key="key"
      class="rwd-table"
    >
      <tbody>
        <tr>
          <th>{{ key.toUpperCase() }} </th>
          <th
            v-for="header in standings[key][0].headers"
            :key="header"
          >
            {{ header }}
          </th>
        </tr>
        <tr
          v-for="team in standings[key].slice(0, 8)"
          :key="team.name"
        >
          <td>{{ team.nickname }}</td>
          <td
            v-for="header in standings[key][0].headers"
            :key="`header-${header}`"
          >
            {{ team.values[header] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

// import {
//   computed,
// } from '@vue/composition-api'

export default {
  components: {},

  props: {
    standings: {
      type: Object,
      default: () => {},
    },
  },

  // setup(props) {
  //   const data = computed(() => {
  //     const arr = new Array(8)
  //     for (var i = 0; i < 8; i++) {
  //       array.push(['', '', '', '', '', ''])
  //     }

  //     console.log('--------------------------------------> standings')
  //     Object.keys(props.standings).forEach(key => {
  //       for (const x of Array(5).keys()) {
  //       console.log(`--------------------------------------> standings ${key}`)
  //       console.log(props.standings[key])
  //     })
  //   })

  //   return {
  //     data,
  //   }
  // },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
// @import 'https://fonts.googleapis.com/css?family=Jomhuria:600,700';

.container {
  --color-text-primary: #1c2a38;
  --color-text-secondary: #8a8f98;
  --color-text-alert: #d72641;
  --color-text-icon: #dbdade;
  --color-bg-primary: $primary;
  --color-bg-secondary: $secondary;
  --color-bg-alert: #fdeaec;
  --color-theme-primary: #623ce6;
}

* {
  //font-family: 'Open Sans', sans-serif;
}

.rwd-table {
  margin: auto;
  min-width: 170px;
  max-width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 2px 0 rgba(#303030, 0.1), 0 4px 4px 0 rgba(#303030, 0.1);
  // margin: 10px auto;
}

.rwd-table tr:first-child {
  border-top: none;
  background: $primary;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: $primary;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: $primary;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: rgb(255, 255, 255);
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: rgb(255, 255, 255);
  // border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #4536a5;
}

.rwd-table th,
.rwd-table td {
  padding: .2em .5em;
}
@media screen and (max-width: 1px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 0px) {
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .1em 0;
  }
  .rwd-table td:first-child {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .rwd-table th,
  .rwd-table td {
    text-align: center;
    //padding: 0.3em 0.3em 0.3em 0.3em  !important;
  }
}

/* the second */
th:nth-child(1) {
  font-weight: 700; font-size: 1.2em; color: $secondary;
}
td:nth-child(1) {
  font-size: 0.8em;
  text-align: left;
}

th:nth-child(2) {
  font-weight: 700; font-size: 0.8em; background: #4536a5;
}
td:nth-child(2) {
  background: #4536a5;
  font-weight: bold;
  font-size: 0.9em;
  color:#8a8f98;
  min-width: 40px;
}

th:nth-child(3) {
  font-weight: 700; font-size: 0.8em; background: #4536a5;
}
td:nth-child(3) {
  background: #4536a5;
  font-weight: bold;
  font-size: 0.9em;
  color:#8a8f98;
  min-width: 40px;
}

th:nth-child(4) {
  font-weight: 700; font-size: 0.8em; background: #4536a5;
}
td:nth-child(4) {
  background: #4536a5;
  font-weight: bold;
  font-size: 0.9em;
  color:#8a8f98;
  min-width: 40px;
}

th:nth-child(5) {
  font-weight: 700; font-size: 0.8em; background: #4536a5;
}
td:nth-child(5) {
  background: #4536a5;
  font-weight: bold;
  font-size: 0.9em;
  color:#8a8f98;
  min-width: 40px;
}
</style>
